import { useState } from "react";
import {
  InputText,
  FormAccordion,
  FormSummaryGrid,
  Button,
  HelperConstants,
  toast
} from "@viva-finance/viva-components";

const { BUTTON_TYPES, BUTTON_SIZES, US_STATES, TOAST_CONFIG } = HelperConstants;

function App() {
  const [items, setItems] = useState([]);
  const [name, setName] = useState('');
  const [availableStates, setAvailableStates] = useState(US_STATES);

  const runName = () => {
    if(availableStates.length === 0){
      toast(TOAST_CONFIG.warn, { message: "No more states available" });
    } else if (name === '') {
      toast(TOAST_CONFIG.warn, { message: "Enter a valid name" });
    }
    else {
      const randIndex = Math.floor(Math.random()*availableStates.length);
      const state = availableStates[randIndex];
  
      const tempItems = [...items, {key: name, value: state.name}]
      setItems(tempItems);
    
      const tempStates = availableStates.filter(item => item.value !== availableStates[randIndex].value);
      setAvailableStates(tempStates);
      setName('');
    }
  };

  return (
    <div className="w-screen h-screen relative z-1">
      <div className="h-6 w-72 m-auto pt-12">
        <InputText
          label="Name"
          name="name"
          type="text"
          placeholder="John Snow"
          onChange={(e) => setName(e.target.value)}
        />
        <Button
          type={BUTTON_TYPES.outline}
          label="Submit"
          onClick={runName}
          size={BUTTON_SIZES.small}
          className="mt-2"
        />
      </div>
      <div className="max-w-2xl pt-36 m-auto">
        <FormAccordion
          header="Results"
        >
          <FormSummaryGrid
            items={items}
          />
        </FormAccordion>
      </div>
    </div>
  );
}

export default App;
