import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '@viva-finance/viva-components/dist/tailwind.css';
import './index.css'
import { Toast } from '@viva-finance/viva-components';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    <Toast />
  </React.StrictMode>
);

